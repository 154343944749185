import { PageTransitionWrapper } from "../../components/PageTransitionWrapper"
import { styleConstants } from "../../style-constants"
import { useAppDispatch, useAppSelector } from "../../state/hooks"
import { TBackendKpi, TBackendMica, TBackendMicaDataEntry, TBackendMicaNetwork, TBackendMicaSingleIndicator, TBackendNetworkTicker, TBackendTickers } from "../../types/backend-data"
import { useCallback, useEffect, useMemo, useState } from "react"
import { isAllMicaDataLoaded, loadUnavailableMicaData } from "../../state/reducers/dashboardReducerHelpers"
import { setSelectedNetwork } from "../../state/actions/dashboardActions"
import { LoadingSpinner } from "../../components/LoadingSpinner"
import { BackendOfflineAlert } from "../../components/BackendOfflineAlert"
import { Transition } from "@headlessui/react"
import { Paragraph } from "../../components/Paragraph"
import { formatEmissionElectricityNumber, formatNetworkName, getSuperscriptUnicodeNumber } from "../MainDashboard/mainDashboardHelpers"
import { TMicaTableContent, TMicaTableHeaders } from "../../types/component-types"
import { InfoTooltip } from "../../components/InfoTooltip"
import { getIconElement, TIconKey } from "../../icons"
import { PoweredByCcri } from "../../components/PoweredByCcri"
import { OutdatedAlert } from "../../components/OutdatedAlert"
import { HeroSection } from "../../sections/HeroSection"
import { Collapsible } from "../../components/Collapsible"
import { ScrollFadingSectionWrapper } from "../../sections/ScrollFadingSectionWrapper"


interface IMicaProps {

}


export const Mica: React.FC<IMicaProps> = (props) => {


  const dispatch = useAppDispatch()

  const [micaLoading, setMicaLoading] = useState<boolean>(true);
  const [tableContent, setTableContent] = useState<TMicaTableContent[]>([]);
  const [tableHeaders, setTableHeaders] = useState<TMicaTableHeaders>([])

  const tickers = useAppSelector<TBackendTickers>(state => state.dashboard.tickers);
  const micaData = useAppSelector<TBackendMica>(state => state.dashboard.micaData);
  const kpiData = useAppSelector<TBackendKpi>(state => state.dashboard.kpiData);
  const selectedNetwork = useAppSelector<TBackendNetworkTicker>(state => state.dashboard.selectedNetwork)
  const isBackendOffline = useAppSelector<boolean>(state => state.session.isBackendOffline)

  const indicatorsGroupInfo = useMemo(() => ({
    1: {
      name: "Energy",
      icon: "electricity"
    },
    2: undefined,
    3: undefined,
    4: {
      name: "GHG Emissions",
      icon: "emissions"
    },
    5: undefined,
    6: undefined,
    7: {
      name: "Waste Production",
      icon: "waste"
    },
    8: undefined,
    9: undefined,
    10: {
      name: "Natural Resources",
      icon: "sun"
    },
  }), []);

  const indicatorsChangeFractionDigits = useMemo(() => ({
    3: 7,
    4: 0,
    6: 7,
  }), []);

  const indicatorsFootnoteNumbers = useMemo(() => ({
    2: 2,
    7: 3,
    8: 4,
    9: 5,
  }), []);


  const generateTableHeader = useCallback(
    (isL2: boolean): TMicaTableHeaders => {

      //Only include ETH network in the header if L2 network!
      const allTableColNames = [
        "Type",
        "Adverse Sustainability Indicator",
        formatNetworkName(selectedNetwork),
        ...(isL2 ? ["ETH Network"] : []), // Conditionally include ETH network col name
        "Metric Description"
      ]
      return allTableColNames
    }, [selectedNetwork]
  )


  const generateTableContent = useCallback((isL2: boolean): TMicaTableContent[] => {

    const iconStrokeWidth = 1.5
    const iconTextColor = "boxBackgroundGray md:text-primaryColor"

    const currencyTableData: TBackendMicaNetwork = micaData[selectedNetwork].data
    const l1TableData: TBackendMicaNetwork | undefined = isL2 && "eth2" in micaData ? (micaData["eth2"] as TBackendMicaDataEntry).data : undefined

    const tableData = Object.values(currencyTableData).map((indicatorData: TBackendMicaSingleIndicator): TMicaTableContent => {
      const indicatorGroupInfo = indicatorsGroupInfo[(indicatorData.indicator as keyof typeof indicatorsGroupInfo)]
      const indicatorUnit = indicatorData.unit === "percentage share" ? "%" : indicatorData.unit
      const indictorFractionDigits = indicatorsChangeFractionDigits[indicatorData.indicator as keyof typeof indicatorsChangeFractionDigits] ?? 2
      const indicatorFootnoteNumber = indicatorsFootnoteNumbers[indicatorData.indicator as keyof typeof indicatorsFootnoteNumbers] ?? undefined
      return {
        icon: indicatorGroupInfo ? getIconElement(indicatorGroupInfo.icon as TIconKey, iconTextColor, iconStrokeWidth) : undefined,
        type: indicatorGroupInfo ? indicatorGroupInfo.name : undefined,
        indicator: `${indicatorData.title}${indicatorFootnoteNumber ? getSuperscriptUnicodeNumber(indicatorFootnoteNumber) : ""}`,
        value: formatEmissionElectricityNumber(indicatorData.result.value, undefined, indictorFractionDigits, indictorFractionDigits, undefined, indicatorUnit, undefined),
        l1Value: l1TableData ? formatEmissionElectricityNumber(indicatorData.result.value, undefined, indictorFractionDigits, indictorFractionDigits, undefined, indicatorUnit, undefined) : undefined,
        info: indicatorData.description,
      }
    })

    return tableData

  }, [micaData, selectedNetwork, indicatorsChangeFractionDigits, indicatorsFootnoteNumbers, indicatorsGroupInfo]);


  const isTableTextCenter = (colIndex: number) => {
    return (colIndex === 1) ? false : true
  }


  const createInfoTooltipWithContainer = (tooltipText: string, wideTooltip?: boolean) => {
    return (
      <div className="flex justify-center items-center">
        <div className="absolute">
          <InfoTooltip
            text={tooltipText}
            type="info"
            wideTooltip={wideTooltip ? true : false}
            customIconHeight={"h-[20px]"}
            customTooltipStyle="!bg-opacity-85"
          />
        </div>
      </div>
    )
  }


  const renderDesktopTable = () => {

    const renderDesktopTableHead = () => {
      const thClasses = "px-3 py-3.5 text-sm text-gray-900"
      const paragraphClasses = "!font-bold text-boxBackgroundGray"
      const colWidthClasses = [
        "min-w-[100px]",
        "w-auto",
        "min-w-[125px]",
        "min-w-[125px]",
        "min-w-[50px]",
      ]
      const tableHeads = tableHeaders.map((name, index) => {
        return (
          <th scope="col" className={`${thClasses} ${colWidthClasses[index]}`} key={`head-col-${index}`}>
            <Paragraph
              content={name}
              isCenter={isTableTextCenter(index)}
              customStyle={paragraphClasses}
            />
          </th>
        )
      })
      return (
        <thead className="bg-slate-100">
          <tr>
            {tableHeads}
          </tr>
        </thead>
      )
    }

    const renderDesktopTableBody = () => {

      const renderValue = (value: string) => {
        return (
          <Paragraph
            content={value}
            isThinText
            isCenter
            customStyle={tableContentTextCenterClass}
          />
        )
      }

      const tdClasses = "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
      const tableContentTextCenterClass = "text-center"
      const tableRows = tableContent.map((rowInfo: TMicaTableContent, index: number) => {
        const hasRowFirstMultilineCol = rowInfo.type && rowInfo.icon
        return (
          <tr key={`body-row-${index}`} >
            {hasRowFirstMultilineCol &&
              <td className={`${tdClasses} font-medium`} rowSpan={3}>
                <div className="w-full flex flex-col justify-center items-center gap-y-2">
                  <div className="w-7">
                    {rowInfo.icon!}
                  </div>
                  <Paragraph
                    content={rowInfo.type!}
                    customStyle={`!text-primaryColor font-semibold ${tableContentTextCenterClass}`}
                  />
                </div>
              </td>
            }
            <td className={tdClasses}>
              <Paragraph
                content={rowInfo.indicator}
                isThinText
              />
            </td>
            <td className={tdClasses}>
              {renderValue(rowInfo.value)}

            </td>
            {rowInfo.l1Value &&
              <td className={tdClasses}>
                {renderValue(rowInfo.l1Value)}
              </td>
            }
            <td className={`${tdClasses} h-full`}>
              {createInfoTooltipWithContainer(rowInfo.info)}
            </td>
          </tr>
        )
      })
      return (
        <tbody className="divide-y divide-gray-200 bg-white">
          {tableRows}
        </tbody>
      )


    }

    return (
      <>
        {renderDesktopTableHead()}
        {renderDesktopTableBody()}
      </>
    )
  }


  const renderMobileTable = () => {

    const createMobileTableKeyValuePair = (key: string, value: string): JSX.Element => {
      return (
        <div className="flex flex-col justify-center items-center">
          <Paragraph
            isCenter
            content={key}
          />
          <Paragraph
            content={value}
            isCenter
            isThinText
          />
        </div>
      )
    }

    const createMobileInfoTooltipWithContainer = (metricHeading: string, tooltipText: string) => {
      return (
        <div className="flex flex-col gap-y-3">
          <Paragraph
            content={metricHeading}
            isCenter
          />
          {createInfoTooltipWithContainer(tooltipText, true)}
        </div>
      )
    }

    const filteredRows: JSX.Element[] = tableContent.reduce<JSX.Element[]>((rowElementsAccumulator, rowInfo: TMicaTableContent, index: number) => {
      // Only include the element if it has both type and icon
      if (rowInfo.type && rowInfo.icon) {
        const rowTypeElement = (
          <tr key={`table-${index}-type`} className="bg-gray-200">
            <td className={`font-medium text-gray-900`}>
              <div className="w-full flex flex-row justify-center items-center gap-x-2 my-6">
                <div className="w-7">
                  {rowInfo.icon!}
                </div>
                <Paragraph
                  content={rowInfo.type!}
                  customStyle={`!font-semibold !text-boxBackgroundGray`}
                />
              </div>
            </td>
          </tr>
        )
        rowElementsAccumulator.push(rowTypeElement);
      }
      const rowRegularElement = (
        <tr key={`table-${index}`} className="bg-white">
          <td className="flex flex-col justify-center items-center gap-y-4 mx-2 xxs:mx-4 my-4 text-gray-500">
            <div className="w-full xs:w-2/3">
              <Paragraph
                content={rowInfo.indicator}
                isCenter
                customStyle={"!font-bold"}
              />
            </div>
            <div className="flex flex-col justify-center items-center gap-y-4 mx-2 my-2">
              <>
                {createMobileTableKeyValuePair(tableHeaders[2], rowInfo.value)}
                {rowInfo.l1Value && createMobileTableKeyValuePair(tableHeaders[3], rowInfo.l1Value)}
              </>
              {
                createMobileInfoTooltipWithContainer(tableHeaders[rowInfo.l1Value ? 4 : 3], rowInfo.info)
              }
            </div>
          </td>
        </tr>
      )
      rowElementsAccumulator.push(rowRegularElement);
      return rowElementsAccumulator;
    }, []);

    const mobileTable = (
      <tbody className="divide-y divide-gray-200">
        {filteredRows}
      </tbody>
    )

    return mobileTable
  }


  const renderTable = () => {

    const wrapTableInContainer = (jsxTable: JSX.Element) => {
      return (
        <div className={`${styleConstants.roundedBorder} overflow-hidden w-full`}>
          <table className="min-w-full h-full rounded-xl border border-tools-table-outline divide-y divide-gray-300 ">
            {jsxTable}
          </table >
        </div >
      )

    }

    return (
      <>
        <div className={`${styleConstants.desktopMdClasses}`}>
          {wrapTableInContainer(renderDesktopTable())}
        </div >
        <div className={`${styleConstants.mobileMdClasses}`}>
          {wrapTableInContainer(renderMobileTable())}
        </div >
      </>
    )
  }


  const renderMicaTable = () => {
    return (
      <div className="flex flex-col w-full max-w-[1150px]">
        <div className="flex justify-center md:justify-end mb-4 md:mr-2">
          <PoweredByCcri />
        </div>
        {renderTable()}
        <div className="flex justify-center md:justify-end md:mr-2">
          <OutdatedAlert
            timestamp={micaData[selectedNetwork].age}
            hasTopMargin
          />
        </div>
      </div>

    )
  }


  const renderTextSection = () => {

    const renderGlossary = () => {

      const renderGlossaryBody = () => {
        const glossaryContent = [
          ["MiCA", "Markets in Crypto-Assets Regulation", "The Markets in Crypto-Assets Regulation (MiCA) institutes uniform EU market rules for crypto-assets. The regulation covers crypto-assets that are not currently regulated by existing financial services legislation. Key provisions for those issuing and trading crypto-assets (including asset-reference tokens and e-money tokens) cover transparency, disclosure, authorisation and supervision of transactions. The new legal framework will support market integrity and financial stability by regulating public offers of crypto-assets and by ensuring consumers are better informed about their associated risks.", "https://www.esma.europa.eu/esmas-activities/digital-finance-and-innovation/markets-crypto-assets-regulation-mica"],
          ["ESMA", "European Securities and Markets Authority", "ESMA is the EU’s financial markets regulator and supervisor. The 2023-2028 strategy guides ESMA in its second decade. It builds on the successful development of ESMA since its establishment and reflects the evolving environment in which it operates.", "https://www.esma.europa.eu/about-esma"],
          ["RTS", "Regulatory Technical Standard", "Regulatory Technical Standards (RTS) are a set of rules and guidelines established by the European Banking Authority (EBA) to ensure that banks and other financial institutions comply with the rules set out in the Second Payment Services Directive (PSD2).", "https://en.cubemos.com/sustainabilityglossary/regulatory-technical-standards-rts#:~:text=Regulatory%20Technical%20Standards%20(RTS)%20are,Payment%20Services%20Directive%20(PSD2)"],
          ["GHG Emissions", "Greenhouse Gas Emissions", "Greenhouse gases constitute a group of gases contributing to global warming and climate change. The Kyoto Protocol, an environmental agreement adopted by many of the parties to the United Nations Framework Convention on Climate Change (UNFCCC) in 1997 to curb global warming, nowadays covers seven greenhouse gases: carbon dioxide (CO\u2082), methane (CH\u2084), nitrous oxide (N\u2082O), hydrofluorocarbons (HFCs), perfluorocarbons (PFCs), sulphur hexafluoride (SF\u2086), and nitrogen trifluoride (NF\u2083). Converting them to carbon dioxide (or CO\u2082) equivalents makes it possible to compare them and to determine their individual and total contributions to global warming.", "https://ec.europa.eu/eurostat/statistics-explained/index.php?title=Glossary:Greenhouse_gas_(GHG)"],
          ["WEEE", "Waste Electrical and Electronic Equipment", "The amount of waste electrical and electronic equipment (widely known as WEEE or e-waste) generated every year in the EU is increasing rapidly. It is now one of the fastest growing waste streams. It includes a large range of devices such as mobile phones, computers, televisions, fridges, household appliances, lamps but also medical devices and photovoltaic panels. E-waste contains a complex mixture of materials, some of which are hazardous. These can cause major environmental and health problems if the discarded devices are not managed properly. Modern electronics also contain rare and expensive resources, including critical raw materials. These can be recycled and re-used if the waste is effectively managed. Improving the collection, treatment and recycling of electrical and electronic equipment at the end of their life can increase resource efficiency and support the shift to a circular economy. It can also contribute to the security of supply for critical raw materials, ultimately enhancing the EU’s strategic autonomy.", "https://environment.ec.europa.eu/topics/waste-and-recycling/waste-electrical-and-electronic-equipment-weee_en"],
          ["DLT Node", "Distributed Ledger Technology Node", "A device or process that is part of a network and that holds a complete or partial replica of records of all transactions on a distributed ledger.", "https://micapapers.com/definitions/dlt-network-node/"],
        ]
        const glossaryBody = (
          <div className="w-full flex flex-col gap-8">
            {
              glossaryContent.map(((entry, index) => {
                return (
                  <div
                    key={`mica-glossary-entry-${index}`}
                    className="flex w-full flex-col md:flex-row gap-4"
                  >
                    <div className="w-full md:w-[120px] shrink-0 flex justify-center md:justify-start items-center">
                      <Paragraph
                        content={entry[0]}
                        isWhiteText
                      />
                    </div>
                    <div className="flex-1">
                      <div className={`${styleConstants.mobileMdClasses}`}>
                        <div className="flex flex-col">
                          <Paragraph
                            content={`${entry[1]}.`}
                            isWhiteText
                            isCenter
                          />
                          <Paragraph
                            content={entry[2]}
                            isThinText
                            isCenter
                          />
                        </div>
                        <div>
                          <Paragraph
                            content={{
                              text: ["(", ")"],
                              links: [entry[3]],
                              linkTexts: ["view source"],
                            }}
                            isThinText
                            customStyle="!font-normal"
                            isCenter
                          />
                        </div>
                      </div>
                      <div className={`${styleConstants.desktopMdClasses}`}>
                        <div className="flex flex-col">
                          <Paragraph
                            content={`${entry[1]}.`}
                            isWhiteText
                          />
                          <Paragraph
                            content={entry[2]}
                            isThinText
                          />
                        </div>
                        <div>
                          <Paragraph
                            content={{
                              text: ["(", ")"],
                              links: [entry[3]],
                              linkTexts: ["view source"],
                            }}
                            isThinText
                            customStyle="!font-normal"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }))
            }
          </div>
        )

        return glossaryBody
      }

      return (
        <Collapsible
          heading="Glossary"
          bodyElem={renderGlossaryBody()}
          isFullWidth
          isInitiallyOpen
        />
      )
    }

    const renderMicaText = () => {
      return (
        <div className="flex flex-col">
          <Paragraph
            content={"MiCA (Markets in Crypto-Asset) Regulation defines roles and responsibilities for entities within the crypto ecosystem. Among other requirements, CASPs (Crypto-Asset Service Providers) and Crypto-Asset Issuers are required to disclose sustainability metrics of currencies they offer. If you are a CASP, CCRI may help you by providing respective data for your website or in your application."}
            isCenter
          />
        </div>
      )
    }


    const renderFootnotes = () => {

      const renderLinkFootnote = (number: number, link: string) => {
        return (
          <Paragraph
            content={{
              text: [`${getSuperscriptUnicodeNumber(number)} `],
              links: [link],
              linkTexts: [link],

            }}
            isCenter
            isCaption
            isLinkNotBold
          />
        )
      }

      const renderMiddleFootnoteGroup = () => {
        const footnotesText = [
          "We derive the values of the electricity sources of relevant countries and calculate a renewable energy share. Considering node locations and node counts, we are able to derive the total renewable energy consumption of the network. (Comparison: US, 20.7 %. Germany, 41.2 %.)",
          "Based on the hardware estimates, we calculate the total weight of the devices. We depreciate the hardware based on the length of the respective manufacturer guarantee and calculate an average annual waste over all hardware devices.",
          "We collect electronic waste recycling rates of countries. If data is unavailable, we use recycling rates from the respective regions. Considering node locations and node counts, we are able to derive the total share of non-recycled electronic waste of the network. (Comparison: US, 85.3 %. Germany, 47.9 %.)",
          "Based on our electronic waste calculation, we further analyzed hardware specifications of a single device and its hazardous waste components. We used the share of hazardous waste as a proxy for all other devices for which such information was not available and ignored components such as cases.",
        ]
        return footnotesText.map((text, index) => {
          const footnoteNumber = index + 2
          return (
            <div
              key={`mica-footnote-${footnoteNumber}`}
            >
              <Paragraph
                content={`${getSuperscriptUnicodeNumber(footnoteNumber)} ${text}`}
                isCenter
                isCaption
              />
            </div>
          )
        })
      }

      return (
        <div className="flex flex-col gap-y-2">
          {
            renderLinkFootnote(1, "https://webgate.ec.europa.eu/regdel/#/delegatedActs/2463?lang=en")
          }
          {
            renderMiddleFootnoteGroup()
          }
        </div>
      )
    }

    return (
      <div className="flex flex-col w-full sm:w-2/3 gap-y-16 justify-self-center">
        {renderMicaText()}
        {renderGlossary()}
        {renderFootnotes()}
      </div>
    )
  }

  const getPageSections = () => {
    return [
      renderMicaTable(),
      renderTextSection()
    ]
  }


  const renderComponent = () => {

    const subheadingText = `ESMA's Final RTS provides a set of required and voluntary sustainability indicators.${getSuperscriptUnicodeNumber(1)}`;

    if (micaLoading) {
      return <LoadingSpinner />
    } else if (false === micaLoading && true === isBackendOffline) {
      return <BackendOfflineAlert />
    } else {
      return (
        <div
          className="flex flex-col w-full items-center gap-y-8">
          <div className="flex flex-col w-full gap-y-16 mb-4">
            <HeroSection
              headingText="MiCA Indicators"
              subheadingText={subheadingText}
            />
          </div>
          <Transition
            className={"w-full flex justify-center"}
            key={`mica-${selectedNetwork}`}
            appear={true}
            show={true}
            enter={`${styleConstants.transitionStandard}`}
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave={`${styleConstants.transitionStandard}`}
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="flex flex-col w-full justify-center items-center gap-16">
              <div className={`${styleConstants.mobileMdClasses}`}>
                {getPageSections()}
              </div>
              <div className={`${styleConstants.desktopMdClasses}`}>
                <ScrollFadingSectionWrapper sections={getPageSections()} />
              </div>
            </div>
          </Transition>
        </div>
      )
    }
  }


  useEffect(() => {

    //load data if not yet loaded, on initial page load
    if (false === isAllMicaDataLoaded()) {
      setMicaLoading(true)
      loadUnavailableMicaData(dispatch)
    }

  }, [dispatch]);


  useEffect(() => {

    //check if all data there on each update, set loading to false if all are there, create table content if data are there
    if (true === isAllMicaDataLoaded()) {
      if (undefined === selectedNetwork) {
        // on initial load, set selectedNetworkName to the first network
        dispatch(setSelectedNetwork(tickers[0]))
      }
      setMicaLoading(false)
    } else if (true === isBackendOffline) {
      setMicaLoading(false)
    }
  }, [dispatch, tickers, micaData, kpiData, isBackendOffline, selectedNetwork]);


  useEffect(() => {

    //update the table state data (is l2, data, headers) on initially (loading done) and on network change
    if (false === micaLoading && false === isBackendOffline) {
      const isL2 = !(kpiData[selectedNetwork].l2_electricity365 === null || kpiData[selectedNetwork].l2_emissions365 === null);
      setTableHeaders(generateTableHeader(isL2));
      setTableContent(generateTableContent(isL2));
    }

  }, [micaLoading, selectedNetwork, isBackendOffline, kpiData, generateTableContent, generateTableHeader]);

  return (
    <PageTransitionWrapper
      component={renderComponent()}
    />)
}